// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-horeca-about-js": () => import("./../../../src/pages/horeca/about.js" /* webpackChunkName: "component---src-pages-horeca-about-js" */),
  "component---src-pages-horeca-benefits-js": () => import("./../../../src/pages/horeca/benefits.js" /* webpackChunkName: "component---src-pages-horeca-benefits-js" */),
  "component---src-pages-horeca-contact-js": () => import("./../../../src/pages/horeca/contact.js" /* webpackChunkName: "component---src-pages-horeca-contact-js" */),
  "component---src-pages-horeca-js": () => import("./../../../src/pages/horeca.js" /* webpackChunkName: "component---src-pages-horeca-js" */),
  "component---src-pages-horeca-partners-js": () => import("./../../../src/pages/horeca/partners.js" /* webpackChunkName: "component---src-pages-horeca-partners-js" */),
  "component---src-pages-horeca-video-js": () => import("./../../../src/pages/horeca/video.js" /* webpackChunkName: "component---src-pages-horeca-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-retail-about-js": () => import("./../../../src/pages/retail/about.js" /* webpackChunkName: "component---src-pages-retail-about-js" */),
  "component---src-pages-retail-benefits-js": () => import("./../../../src/pages/retail/benefits.js" /* webpackChunkName: "component---src-pages-retail-benefits-js" */),
  "component---src-pages-retail-contact-js": () => import("./../../../src/pages/retail/contact.js" /* webpackChunkName: "component---src-pages-retail-contact-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-retail-partners-js": () => import("./../../../src/pages/retail/partners.js" /* webpackChunkName: "component---src-pages-retail-partners-js" */),
  "component---src-pages-retail-video-js": () => import("./../../../src/pages/retail/video.js" /* webpackChunkName: "component---src-pages-retail-video-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

